<template>
  <div :class="'popup_wrap popup_wrap_pdf ' + layerStyle">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <template v-if="type === 'A'">
      <div class="popup_cont">
        <h1 class="page_title">Invoice</h1>
        <div class="content_box mt10">
          <p v-if="memberDetail.userCtrCd === 'IN'" class="text_center mt10 mb10">
            <input type="checkbox" id="combine_pop_chk1" v-model="checkedValues" value="T" @change="clickFunc">
            <label for="combine_pop_chk1" class="mr20"><span></span>Total</label>

            <input type="checkbox" id="combine_pop_chk2" v-model="checkedValues" value="B" @change="clickFunc">
            <label for="combine_pop_chk2" class="mr20"><span></span>Break up</label>
          </p>
          <table v-else class="tbl_search">
            <colgroup>
              <col width="60px">
              <col width="200px">
              <col width="250px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>TO</th>
                <td>
                  <select id="" name="selectTo" v-model="dtKnd" @change="changeSelectFuc($event)">
                    <option value="">{{ $t('msg.CSBK100.050') }}</option>
                    <option value="bkNm">{{ $t('msg.ONEX010P100.007') }}</option>
                    <option value="blNm">{{ $t('msg.ONEX010P100.009') }}</option>
                  </select>
                </td>
                <td class="pl0">
                  <input type="text" name="arrayTo" v-model="arrayTo">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt10 text_center">
          <a class="button blue lg" href="#" @click.prevent="fnChangePrint();">{{ $t('msg.ARAI200.007') }}</a>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="popup_cont">
        <h1 class="page_title">{{ $t('msg.REPORT_PDF.118') }}<!-- Invoice --></h1>
        <div class="content_box">
          <div class="clearfix">
            <div class="float_left col_3" style="max-height:300px; overflow-y:auto;">
              <table class="tbl_list font_sm gridtype">
                <colgroup>
                  <col width="*">
                  <col width="*">
                  <!-- <col v-if="isNotPRD && isNotSTG" width="100"> -->
                </colgroup>
                <thead>
                  <tr>
                    <th>{{ $t('msg.REPORT_PDF.002') }}</th>
                    <th>{{ $t('msg.REPORT_PDF.004') }}</th>
                    <!-- <th v-if="isNotPRD && isNotSTG">
                      Link
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(vo, idx) in items" :key="'tbl_' + idx">
                    <td>{{ vo.blNo }}</td>
                    <td>
                      <template v-if="vo.authYn !== 'Y'">
                        N/A
                      </template>
                      <template v-else-if="vo.exrateYn !== 'Y'">
                        NO_EXCHANGE_RATE
                      </template>
                      <template v-else-if="vo.proformaYn === 'Y'">
                        Proforma
                      </template>
                      <template v-else-if="vo.invIssNo === undefined || vo.invIssNo === ''">
                        NO_INV_ISS_NO
                      </template>
                      <template v-else>
                        {{ vo.invIssNo }}
                      </template>
                    </td>
                    <!-- <td v-if="isNotPRD && isNotSTG">
                      <a href="#" @click.prevent="copyURL(vo.blNo, vo.invIssNo)">Link</a>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float_left text_center col_7 pl20" style="max-height:500px; overflow-y:auto;">
              <vue-pdf-app style="height: 300px;" :pdf="pdf.pdfUrl" :config="pdfConfig.config"
                           :id-config="pdfConfig.idConfig" theme="light"
              ></vue-pdf-app>
              <div class="paging print">
                <span class="num">
                  <input type="number" :id="pdfConfig.idConfig.pageNumber" class="pageNumber" maxlength="2">
                  <span :id="pdfConfig.idConfig.numPages" class="pageNumber">-</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="mt10 text_center">
          <div class="button blue lg" v-show="couponYn" style="cursor: auto; background-color: red; margin-right: 2px;">쿠폰 사용완료</div>
          <a class="button blue lg" href="#" @click.prevent="fnDownlaod();">{{ $t('msg.REPORT_PDF.101') }}<!-- 다운 --></a>
          <a class="button blue lg" :id="pdfConfig.idConfig.print">{{ $t('msg.REPORT_PDF.102') }}<!-- 인쇄 --></a>
          <a class="button blue lg" href="#" @click.prevent="openSendEmailPopup();">{{ $t('msg.REPORT_PDF.103')
          }}<!-- 공유 --></a>
        </div>
      </div>

      <div>
        <win-layer-pop class="pdf_send_email">
          <component
            v-if="customComponent"
            :is="customComponent"
            @close="closeSendEmailPopup"
            :parent-info="sendEmailParam"
          />
        </win-layer-pop>
      </div>
    </template>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commonPdf from '@/plugins/commonPdf'
import 'vue-pdf-app/dist/icons/main.css'
import reportInfo from '@/api/rest/trans/reportInfo'
import CalcIssue from '@/api/rest/settle/calcIssue'

export default {
  name: 'CombineInvoicePop',
  components: {
    vuePdfApp: () => import('vue-pdf-app'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    PdfSendEmailPop: () => import('@/pages/pdf/popup/PdfSendEmailPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      customComponent: null,
      sendEmailParam: {},
      type: '',
      layerStyle: 'width_600',
      items: [],
      toInfo: {},
      userInfo: {},
      pdf: {},
      pdfConfig: {
        config: {},
        idConfig: {}
      },
      isNotPRD: true,
      isNotSTG: true,
      popInvoiceList: [],
      isCheckAll: false,
      couponYn: false,
      checkCnt: 0,
      dtKnd: 'bkNm',
      arrayTo: '',
      tmpItems: [],
      strParams: [],
      initItems: [],
      total: 'N',
      breakUp: 'N',
      checkedValues: []
    }
  },
  computed: {
    ...rootComputed,
    isEkmtc () {
      return sessionStorage.getItem('loginId').indexOf('EKMTC_') !== -1
    }
  },
  //실시간적으로 변하는 곳에 사용
  watch: {
    parentInfo () {
      if (this.$props.parentInfo.items !== undefined) {
        this.type = 'A'
        this.layerStyle = 'width_600'
        // this.setItems(this.$props.parentInfo.items)
      }
    }
  },
  created () {
    this.isNotPRD = commonPdf.isNotPRD()
    this.isNotSTG = commonPdf.isNotSTG()
    this.init()
  },
  methods: {
    async init () {
      this.pdfConfig = commonPdf.getPdfConfig()
      this.type = this.memberDetail.userCtrCd === 'IN' ? 'A' : ''

      // 로그인 여부 체크
      if (!this.auth.userId) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
        return
      }

      await reportInfo.getReportInfoUser('')
        .then(async res => {
          console.log('getReportInfoUser res @@@@ ', res)

          this.userInfo = res.data
        })

      if (this.$props.parentInfo.items !== undefined) {
        this.items = this.$_.cloneDeep(this.$props.parentInfo.items)

        // 인도 인보이스 출력 아닐때만.
        if (this.memberDetail.userCtrCd !== 'IN') {
          this.type = this.$props.parentInfo.type
        }

        let items = this.$props.parentInfo.items
        let strParams = []

        this.$props.parentInfo.items.forEach((vo) => {
          strParams.push('arrBlNo=' + vo.blNo)
        })

        await reportInfo.getProformaInfoCheck(strParams.join('&'))
        .then(async res => {
          this.initItems = res.data

          items.forEach(vo => {
            const list = res.data[vo.blNo]
            if (list !== undefined) {
              list.forEach(tvo => {
                this.strParams.push(tvo.invIssNo + ',' + tvo.blNo)
              })
            }
          })
        }).catch(err => {
          console.log(err)
        })

        let blArrStr = ''

        for (let i = 0; i < this.$props.parentInfo.items.length; i++) {
          if (blArrStr !== '') {
            blArrStr += ','
          }

          blArrStr += this.$props.parentInfo.items[i].blNo
        }

        let req = {
          blNo: blArrStr
        }

        await CalcIssue.couponYn(req).then(response => {
          if (response.data.couponList[0].useYn !== 'N') {
            this.couponYn = true
          }
        }).catch(e => {
          console.log(e)
        })

        this.isCheckAll = true

        if (this.type === 'A') {
          const strParams = [] // bl 추가정보 조회 파라미터

          this.items.forEach(vo => {
            strParams.push('arrBlNo=' + vo.blNo)
          })

          await Promise.all([
            reportInfo.getReportOption(strParams.join('&'))
              .then(res => {
                this.toInfo = res.data
                this.arrayTo = res.data.bkNm
              })
          ])
        } else {
          this.layerStyle = ' width_1000'
          this.setItems(this.items, 'init')
        }
      }

      commonPdf.commonInit()
    },
    fnChangePrint () {
      if (this.type === 'A') {
        this.type = 'B'
        this.layerStyle = ' width_1000'

        this.setItems(this.items, 'init')
      }
    },
    async setItems (items, flag) {
      let checkBoxEl = document.querySelectorAll('input[name="checkBox"]')
      for (let checkBox of checkBoxEl) {
        if (!checkBox.checked) {
          this.isCheckAll = false
          break
        } else {
          this.isCheckAll = true
        }
      }
      // let agtCd = this.userInfo.agtCd //대리점 코드
      // let agtOrgCd = this.userInfo.agtOrgCd //대리점
      let usrNo = this.auth.userId
      let ctrCd = this.memberDetail.userCtrCd
      let plcCd = this.memberDetail.bkgPlcCd || ''
      let eiCatCd = this.$props.parentInfo.eiCatCd || ''

      if (commonPdf.isNotPRD()) {
        if (this.$props.parentInfo.testUsrNo) {
          usrNo = this.$props.parentInfo.testUsrNo
        }
        if (this.$props.parentInfo.testCtrCd) {
          ctrCd = this.$props.parentInfo.testCtrCd
        }
        if (this.$props.parentInfo.testBkgPlcCd) {
          plcCd = this.$props.parentInfo.testBkgPlcCd
        }
        if (this.$props.parentInfo.testAgtCd) {
          this.userInfo.agtCd = this.$props.parentInfo.testAgtCd
        }
      }
      const strParams = [] //추가정보 조회 파라미터
      strParams.push('eiCatCd=' + eiCatCd)
      items.forEach(vo => {
        strParams.push('arrBlNo=' + vo.blNo)
      })
      const newItems = []
      await reportInfo.getProformaInfoCheck(strParams.join('&'))
        .then(async res => {
          items.forEach(vo => {
            const list = res.data[vo.blNo]
            if (list !== undefined && list.length > 0) {
              this.userInfo.agtNo = list[0].agtNo

              if (list[0].proformaYn === 'Y') {
                newItems.push({
                  blNo: vo.blNo,
                  remark: this.$ekmtcCommon.isEmpty(list[0].rmk) ? '' : list[0].rmk,
                  proformaYn: 'Y',
                  invIssNo: '',
                  issDt: moment().format('YYYYMMDD'),
                  convIssDt: moment().format('YYYY-MM-DD'),
                  filter: '',
                  cstNo: list[0].fnCstNo || list[0].bkgShprCstNo,
                  authYn: list[0].authYn,
                  exrateYn: list[0].exrateYn,
                  invKndCd: list[0].invKndCd,
                  invIssCatCd: list[0].invIssCatCd
                })
              } else {
                list.forEach(tvo => {
                    this.strParams.forEach(svo => {
                      if (tvo.invIssNo === svo.split(',')[0] && tvo.blNo === svo.split(',')[1]) {
                        newItems.push({
                          blNo: vo.blNo,
                          remark: this.$ekmtcCommon.isEmpty(tvo.rmk) ? '' : tvo.rmk,
                          proformaYn: tvo.proformaYn,
                          invIssNo: tvo.proformaYn === 'Y' ? '' : tvo.invIssNo || '',
                          issDt: moment(tvo.issDt).format('YYYYMMDD'),
                          convIssDt: moment(tvo.issDt).format('YYYY-MM-DD'),
                          filter: tvo.proformaYn === 'Y' ? '' : 'BL',
                          cstNo: tvo.fnCstNo,
                          // to: this.$ekmtcCommon.isEmpty(this.arrayTo) ? '' : encodeURIComponent(this.arrayTo),
                          authYn: tvo.authYn,
                          exrateYn: tvo.exrateYn,
                          invIssCatCd: tvo.invIssCatCd || '',
                          invKndCd: tvo.invKndCd || ''
                        })
                      } else if (tvo.exrateYn === 'N') {
                        newItems.push({
                          blNo: vo.blNo,
                          remark: this.$ekmtcCommon.isEmpty(tvo.rmk) ? '' : tvo.rmk,
                          proformaYn: tvo.proformaYn,
                          invIssNo: tvo.proformaYn === 'Y' ? '' : tvo.invIssNo || '',
                          issDt: moment(tvo.issDt).format('YYYYMMDD'),
                          convIssDt: moment(tvo.issDt).format('YYYY-MM-DD'),
                          filter: tvo.proformaYn === 'Y' ? '' : 'BL',
                          cstNo: tvo.fnCstNo,
                          // to: this.$ekmtcCommon.isEmpty(this.arrayTo) ? '' : encodeURIComponent(this.arrayTo),
                          authYn: tvo.authYn,
                          exrateYn: tvo.exrateYn,
                          invIssCatCd: tvo.invIssCatCd || '',
                          invKndCd: tvo.invKndCd || ''
                        })
                      }
                    })
                })
              }
            } else {
              newItems.push({
                blNo: vo.blNo,
                remark: this.$ekmtcCommon.isEmpty(vo.remark) ? '' : vo.remark,
                proformaYn: 'N',
                invIssNo: '',
                filter: '',
                cstNo: ''
              })
            }
          })
        })

      // 직원 아이디로 조회시, asPayAgtOrgNo 겂 변경
      // if (this.isEkmtc && this.memberDetail.userCtrCd === 'IN') {
      if (this.memberDetail.userCtrCd === 'IN') {
        // this.userInfo.payAgtOrgCd = this.userInfo.agtNo
        // this.userInfo.agtNo = 'BOMBOM'
      }

      const userVo = {
        ...this.userInfo,
        usrNo: usrNo,
        ctrCd: ctrCd,
        plcCd: plcCd,
        to: this.$ekmtcCommon.isEmpty(this.arrayTo) ? '' : this.arrayTo,
        totCal: this.checkedValues[0],
        eiCatCd: eiCatCd
      }

      // 정산발행 > 그리드 INVOICE 출력 버튼, 하단 INVOICE 출력 버튼
      // 국가 베트남, 부킹 PLC 코드가 'HPH' 이면, 부킹대리점코드(agtCd) 'VNCHPH' 로 전달
      // if (ctrCd === 'VN') {
      //   if (plcCd === 'HPH') {
      //     userVo.agtCd = 'VNCHPH'
      //   }
      // }

      this.items = newItems
      this.items.remark = newItems.remark
      this.pdf = await commonPdf.setCombineInvoice(this.items, userVo)
      if (this.pdf.rejectItem !== null) {
        if (this.pdf.rejectItem.authYn !== 'Y') {
          this.openAlert(this.$t('msg.SETT010T010.156'))
        } else if (this.pdf.rejectItem.exrateYn !== 'Y') {
          if (ctrCd === 'PH') {
            this.openAlert('Billing will be available after vessel`s departure\nPlease contact our Customer Service Team at mnlcs@ekmtc.com')
          } else if (ctrCd === 'SG') {
            this.openAlert('Exchange rate is not reflected.\n Please contact our BL team.\n sined@ekmtc.com')
          } else {
            this.openAlert(this.$t('msg.SETT010T010.157'))
          }
        } else if (this.pdf.rejectItem.proformaYn !== 'Y' && (this.pdf.rejectItem.invIssNo === undefined || this.pdf.rejectItem.invIssNo === '')) {
          this.openAlert(this.$t('msg.SETT010T010.158'))
        }
        this.$emit('close')
      }
    },
    fnDownlaod () {
      const items = this.items || []

      if (items.length === 0) {
        return
      }

      items.forEach(p => {
        // if (res.data[obj.blNo]) {
        //   obj.proformaYn = res.data[obj.blNo]
        // } else {
        //   obj.proformaYn = 'N'
        // }
        if (p.proformaYn === 'Y') {
          commonPdf.download(p.pdfUrl, 'KMTC' + p.blNo + ' Invoice.pdf')
          // commonPdf.download(p.pdfUrl, p.blNo + '.pdf')
        } else {
          commonPdf.download(p.pdfUrl, 'KMTC' + p.blNo + '_' + p.invIssNo + ' Invoice.pdf')
          // commonPdf.download(p.pdfUrl, p.invIssNo + '.pdf')
        }
      })
    },
    openSendEmailPopup () {
      this.sendEmailParam = {
        emailUrl: this.pdf.emailUrl,
        emailFiles: this.pdf.emailFiles,
        type: 'AN',
        sndDocNo: this.items[0].blNo,
        sndDocKndCd: '06',
        sndCtrCd: this.memberDetail.userCtrCd,
        sndPlcCd: this.memberDetail.bkgPlcCd,
        sndUserNo: this.auth.userId
      }
      this.customComponent = 'PdfSendEmailPop'
      commonPdf.layerOpen('.pdf_send_email')
    },
    closeSendEmailPopup () {
      this.customComponent = null
      commonPdf.layerClose('.pdf_send_email')
    },
    async copyURL (blNo, invIssNo) {
      try {
        const url = `http://172.17.10.53:8090/PDF/pdfBlInvoice.do?as_bl_no=${blNo}&as_inv_iss_no=${invIssNo}`
        await navigator.clipboard.writeText(url)
        alert('Copied')
      } catch (e) {
        alert('Cannot copy')
      }
    },
    changeSelectFuc (e) {
      var opt = e.target.value
      if (this.$ekmtcCommon.isEmpty(opt)) {
        this.arrayTo = ''
      } else {
        this.arrayTo = this.toInfo[opt]
      }
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    clickFunc (event) {
      for (let i = 0; i < this.checkedValues.length; i++) {
        if (this.checkedValues[i] !== event.target.value) {
          console.log(this.checkedValues[i])
          this.checkedValues.splice(i, 1)
        }
      }
    }
  }
}

</script>

<style>
body {
  min-width: 100% !important;
}

.width_1000 {
  width: 1000px !important;
}

.width_600 {
  width: 600px !important;
}

.width_700 {
  width: 700px !important;
}
</style>
